import React from 'react';
import ProjectItem from './ProjectItem.tsx';
import './ProjectList.css';
import auraLogo from '../assets/aura-logo.png';
import noteMesh from '../assets/notemesh.jpg';
import visionTranslate from '../assets/visiontranslate.avif';
import boogieBattle from '../assets/boogie_home.jpg';
interface ProjectItemProps {
  id: number;
  name: string;
  description: string;
  mediaType: 'image' | 'video';
  media: string;
  link?: string;
}

const projects: ProjectItemProps[] = [
  { id: 1, name: "LeetGPU: Online CUDA Playground", description: "Write and execute CUDA code on the web, no GPU required, for free.", mediaType: "video", media: "https://www.youtube.com/embed/k2q43zORg10?si=BDUalzB7yt-reFF-", link: "https://leetgpu.com" },
  { id: 2, name: 'BoogieBattle: 3D Dance Battle', description: 'Use simple gestures to animate a 3D version of yourself performing insane breakdance moves and challenge friends side by side', mediaType: 'image', media: boogieBattle, link: 'https://devpost.com/software/boogie-battle' },
  { id: 3, name: 'VidBite: Visualize Anything', description: 'Visualize any STEM concept with AI-generated 3Blue1Brown-style videos.', mediaType: 'video', media: 'https://www.youtube.com/embed/qEt35pTzZb0?si=rMi_FPSu0UxGNbFP' },
  { id: 4, name: 'AURA: AI Dating Assistant', description: 'Generate witty and engaging responses to conversations. Scaled to over 200+ users and growing', mediaType: 'image', media: auraLogo, link: 'https://feeltheaura.com' },
  { id: 5, name: 'Nina: AI-powered File Manager', description: 'Use natural language to search, edit, and manage files', mediaType: 'video', media: "https://www.youtube.com/embed/co2eU0mG71g?si=KXrLBBR3ryNZvAc4", link: 'https://github.com/ishaan-arya/nina' },
  { id: 6, name: 'VisionTranslate: Real Time Translation for Vision Pro', description: 'Allows users to translate speech-to-text in real time with their Vision Pro. Game changer for the hearing impaired.', mediaType: 'image', media: visionTranslate, link: 'https://apps.apple.com/us/app/visiontranslate-live-captions/id6705126831' },
  { id: 7, name: 'NoteMesh: Collaborative Note-Taking', description: 'Provides compiled class notes using ideas from all students and allow teachers to track class engagement and understand material comprehension', mediaType: 'image', media: noteMesh, link: 'https://github.com/ishaan-arya/note-mesh-student' },
  { id: 8, name: 'Sordle: Wordle Solver', description: 'Information theory to solve Wordle in, on average, 3.5 guesses', mediaType: 'video', media: "https://www.youtube.com/embed/cMmQPt0_nxU?si=Gn83Wh2rd88cDX4U", link: 'https://github.com/kunal-mansukhani/wordle-solver' }

];
const ProjectList: React.FC = () => (
  <section id="projects">
    <h2>My Projects</h2>
    {projects.map(project => (
      <ProjectItem key={project.id} {...project} />
    ))}
  </section>
);

export default ProjectList;